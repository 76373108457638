window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

// Adiciona a meta tag robots para evitar indexação
const metaTag = document.createElement('meta');
metaTag.name = "robots";
metaTag.content = "noindex, nofollow";
document.head.appendChild(metaTag);

  // Detecta se é um dispositivo móvel
  function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  // Carrega o script adequado com base no dispositivo
  if (isMobile()) {
    // Versão Mobile: exibe o pop-up ao atingir 95% da rolagem da página
    var scriptMobile = document.createElement("script");
    scriptMobile.src = "https://456a04a9d53a41259f8b984707240229.js.ubembed.com";
    scriptMobile.async = true;
    document.head.appendChild(scriptMobile);

    // Aciona o pop-up ao atingir 95% de rolagem no mobile
    window.addEventListener("scroll", function() {
      var scrollPercentage = (window.scrollY + window.innerHeight) / document.body.scrollHeight;
      if (scrollPercentage >= 0.95) {
        // Abaixo, o código para garantir que o pop-up seja ativado
        // Aqui você não precisa adicionar código adicional, pois o gatilho já está configurado no script Unbounce
      }
    }, { once: true }); // "once: true" garante que o evento será acionado apenas uma vez

  } else {
    // Versão Desktop: exibe o pop-up ao tentar sair da página
    var scriptDesktop = document.createElement("script");
    scriptDesktop.src = "https://555504a9d53a41259f8b984707240229.js.ubembed.com";
    scriptDesktop.async = true;
    document.head.appendChild(scriptDesktop);

    // Script para acionar o pop-up ao tentar sair da página no desktop
    document.addEventListener("mouseleave", function(event) {
      if (event.clientY <= 0) {
        // Aqui, o código para garantir que o pop-up seja ativado
        // O gatilho de saída já é configurado no próprio script Unbounce
      }
    });
  }